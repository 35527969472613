/*====================
Mainmenu Area 
=====================*/

.mainmenunav {
    ul {
        &.mainmenu {
            display: flex;
            justify-content: flex-end;
            @extend %liststyle;
            flex-wrap: wrap;

            > li {
                margin: 0 15px;
                position: relative;
                cursor: pointer;
                @media #{$lg-layout} {
                    margin: 0 12px;
                }
                > a {
                    @extend %transition;
                    color: var(--color-tertiary);
                    font-size: 16px;
                    font-weight: 500;
                    padding: 20px 0;
                    display: inline-block;
                }
                &:hover {
                    > a {
                        color: var(--color-primary);
                    }
                }
                &:first-child {
                    margin-left: 0;
                }
                // &:last-child {
                //     margin-right: 0;
                // }
            }
        }
    }
}

.humberger-menu {
    span {
        &.text-white {
            color: var(--color-midgray) !important;
        }
    }
}

.dropdown {
    button#dropdown-basic-button {
        color: var(--color-gray);
        background-color: transparent;
        border-color: transparent;
        &:focus {
            outline: none;
            box-shadow: none;
        }
        svg {
            width: 25px;
            height: 25px;
        }
        &::after {
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.2em;
            content: "";
            border-top: 0.3 em solid;
            border-right: 0.3 em solid transparent;
            border-bottom: 0;
            border-left: 0.3 em solid transparent;
        }
    }
}
.dropdown-item {
    &:active {
        color: var(--color-primary);
    }
}
