/*--------------------
Footer Styles 
----------------------*/

.footer-wrapper {
    position: relative;
}

.footer-left {
    background: linear-gradient(145deg, var(--color-primary) 0%, var(--color-secondary) 100%);
    padding: 120px 120px;
    position: relative;
    z-index: 2;
    margin-right: -15px;
    border-top-right-radius: 6px;

    @media #{$lg-layout} {
        padding: 120px 80px;
    }

    @media #{$md-layout} {
        padding: 60px 70px;
    }

    @media #{$sm-layout} {
        padding: 60px 30px;
    }

    &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        content: "";
        z-index: 1;
        opacity: 0.5;
        border-top-right-radius: 6px;
    }
}

.footer-area,
.footer-style-3 {
    .logo {
        a {
            display: block;
            img {
                max-height: 60px;
            }
        }
    }
}

/*------------------------
    Footer Style One  
-------------------------*/

/* Footer Style Three  */
.footer-style-3 {
    .logo {
        margin-bottom: 30px;
        margin-top: 30px;
        position: relative;
    }
    .text {
        color: var(--color-white);
        p {
            font-size: 14px;
            a {
                transition: 0.3s;
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }
}
