/* ---------------------------
Testimonial Styles 
------------------------------*/

.react-tabs__tab-panel {
    &.react-tabs__tab-panel--selected {
        .rn-testimonial-content {
            opacity: 1;
            -webkit-filter: blur(0);
            filter: blur(0);
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
        }
    }
}
.rn-testimonial-content {
    -webkit-filter: blur(30px);
    filter: blur(30px);
    opacity: 0;
    -webkit-transform: scale(0.2);
    -ms-transform: scale(0.2);
    transform: scale(0.2);
    -webkit-transition: opacity 0.4s cubic-bezier(0.82, 0, 0.12, 1) 0.2s,
        -webkit-filter 0.3s cubic-bezier(0.82, 0, 0.12, 1) 0.1s, -webkit-transform 0.6s cubic-bezier(0.82, 0, 0.12, 1);
    transition: opacity 0.4s cubic-bezier(0.82, 0, 0.12, 1) 0.2s,
        -webkit-filter 0.3s cubic-bezier(0.82, 0, 0.12, 1) 0.1s, -webkit-transform 0.6s cubic-bezier(0.82, 0, 0.12, 1);
    transition: filter 0.3s cubic-bezier(0.82, 0, 0.12, 1) 0.1s, transform 0.6s cubic-bezier(0.82, 0, 0.12, 1),
        opacity 0.4s cubic-bezier(0.82, 0, 0.12, 1) 0.2s;
    transition: filter 0.3s cubic-bezier(0.82, 0, 0.12, 1) 0.1s, transform 0.6s cubic-bezier(0.82, 0, 0.12, 1),
        opacity 0.4s cubic-bezier(0.82, 0, 0.12, 1) 0.2s, -webkit-filter 0.3s cubic-bezier(0.82, 0, 0.12, 1) 0.1s,
        -webkit-transform 0.6s cubic-bezier(0.82, 0, 0.12, 1);
    .inner {
        padding-bottom: 20px;
        p {
            font-size: 30px;
            line-height: 48px;
            color: #18181b;
            font-weight: 500;
            margin-bottom: 0;
            margin-top: -16px;
            position: relative;
            z-index: 2;

            @media #{$lg-layout} {
                font-size: 24px;
                line-height: 44px;
            }
            @media #{$md-layout} {
                font-size: 28px;
                line-height: 48px;
                padding: 0;
            }
            @media #{$sm-layout} {
                font-size: 20px !important;
                line-height: 37px !important;
                padding: 0;
                font-weight: 400;
            }

            @media #{$large-mobile} {
                font-size: 18px !important;
                line-height: 34px !important;
            }

            &::before {
                position: absolute;
                width: 177px;
                height: 100%;
                left: -64px;
                top: -57px;
                // background-image: url(/assets/images/client/image-1.png);
                content: "";
                opacity: 1;
                background-repeat: no-repeat;
                z-index: -1;
                @media #{$lg-layout} {
                    left: 0;
                }
                @media #{$md-layout} {
                    left: 0;
                    top: 0;
                }
                @media #{$sm-layout} {
                    top: -15px;
                    background-size: 100px;
                    left: 50%;
                    right: 0;
                    width: 100%;
                    margin-left: -48px;
                }
            }
        }
    }
    .author-info {
        h6 {
            font-size: 18px;
            line-height: 50px;
            color: #8c8c8c;
            font-weight: 300;
            margin-bottom: 0;

            @media #{$sm-layout} {
                line-height: 20px;
                font-size: 14px;
            }
            span {
                color: var(--color-primary);
                text-transform: uppercase;
                font-weight: 500;
            }
        }
    }
}

.rn-testimonial-light {
    .rn-testimonial-content {
        .inner {
            p {
                color: #c6c9d8;
            }
        }
        .author-info {
            h6 {
                color: #ffffff;
                span {
                    color: #c6c9d8;
                    text-transform: uppercase;
                }
            }
        }
    }
}
.rn-paralax-testimonial {
    .rn-testimonial-content {
        .inner {
            p {
                color: #ffffff;
                &::before,
                &::after {
                    opacity: 0.1;
                }
            }
        }
    }
}

/* ------------------------------
Testimonial Thumbnail 
-------------------------------- */

ul {
    &.testimonial-thumb-wrapper {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 510px;
        margin: 0px auto;
        margin-bottom: -30px;
        outline: none;
        @media #{$large-mobile} {
            margin-bottom: -10px;
        }
        li {
            padding-left: 15px;
            padding-right: 15px;
            flex-basis: 33.33%;
            margin-bottom: 30px;
            outline: none;
            cursor: pointer;
            @media #{$sm-layout} {
                flex-basis: 33.33%;
                margin-bottom: 14px;
                padding-left: 7px;
                padding-right: 7px;
            }

            @media #{$large-mobile} {
                flex-basis: 50%;
                padding-left: 5px;
                padding-right: 5px;
                margin-bottom: 10px;
            }
            .thumb {
                position: relative;
                display: inline-block;
                width: 100%;
                img {
                    @extend %transition;
                    border-radius: 6px;
                    width: 100%;
                    max-height: 140px;
                    min-height: 140px;
                    object-fit: cover;
                    &:hover {
                        transform: scale(1.1);
                        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
                    }
                }
                &::after {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 0;
                    background-color: hsla(0, 0%, 100%, 0.5);
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    cursor: pointer;
                    border-radius: 6px;
                    @extend %transition;
                }
            }
            &.react-tabs__tab--selected {
                .thumb {
                    &::after {
                        background-color: hsla(0, 0%, 100%, 0);
                    }
                    img {
                        transform: scale(1.1);
                        object-fit: contain;
                        border-radius: 6px;
                        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
                        @media #{$sm-layout} {
                            transform: none;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}
