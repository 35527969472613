/*=====================
    Header Area 
=======================*/

.header-area {
    .header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 50px 0;
        flex-wrap: wrap;
        @media #{$sm-layout} {
            padding: 15px 0;
        }
        @media #{$md-layout} {
            padding: 30px 0;
        }
        @media #{$lg-layout} {
            padding: 30px 0;
        }
        .header-hamberger {
            display: flex;
            align-items: center;
            .hamberger {
                margin-right: 27px;
            }
        }
        a {
            &.rn-btn {
                &.theme-color {
                    border-color: var(--color-primary);
                    color: var(--color-primary);
                }
                padding: 0 25px;
                height: 46px;
                display: inline-block;
                line-height: 42px;
                border: 2px solid #b1b4c1;
                border-radius: 6px;
                color: #c6c9d8;
                font-size: 14px;
                @extend %transition;
                position: relative;
                z-index: 2;
                @media #{$sm-layout} {
                    padding: 0 11px;
                    height: 39px;
                    line-height: 33px;
                    font-size: 12px;
                }
                @media #{$large-mobile} {
                    height: 34px;
                    line-height: 29px;
                }
                &::before {
                    display: none;
                }
                &:hover {
                    border-color: transparent;
                    transform: translateY(-3px);
                    color: #ffffff;
                    border: 2px solid var(--color-primary);
                    background: var(--color-primary);
                    border-radius: 6px;
                }
                @media #{$sm-layout} {
                    display: none;
                }
            }
        }

        .logo {
            a {
                img {
                    @media #{$sm-layout} {
                        width: 120px;
                    }
                }
            }
        }
    }
    &.color-white {
        a {
            &.rn-btn {
                border: 2px solid #ffffff;
                color: #ffffff;
            }
        }
    }

    &.color-black {
        a {
            &.rn-btn {
                border: 2px solid rgba(29, 29, 36, 0.5);
                color: $body-color;
            }
        }
    }

    // Static Header
    &.header--static {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 15px;
        padding-bottom: 15px;

        @media #{$sm-layout} {
            padding-left: 15px;
            padding-right: 15px;
        }
        .header-wrapper {
            padding: 7px 0;
            .logo {
                a {
                    img {
                        @media #{$sm-layout} {
                            width: auto;
                        }
                    }
                }
            }
            a,
            button {
                &.btn-default {
                    padding: 13px 30px;
                }
            }
        }
    }

    // Header Fixed
    &.header--fixed {
        position: absolute;
        top: 0;
        z-index: 999;
        padding-left: 50px;
        padding-right: 50px;
        width: 100%;
        background: transparent;
        transition: 0.3s;

        @media #{$lg-layout} {
            padding-left: 15px;
            padding-right: 15px;
        }

        @media #{$md-layout} {
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        @media #{$sm-layout} {
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .header-wrapper {
            padding: 20px 0;
            transition: 0.3s;
            .logo {
                a {
                    img {
                        transition: 0.5s;
                        &.logo-1 {
                            display: block;
                        }
                        &.logo-2 {
                            display: none;
                        }
                    }
                }
            }
            .mainmenunav {
                ul {
                    &.mainmenu {
                        > li {
                            > a {
                                position: relative;
                                &::after {
                                    position: absolute;
                                    content: "";
                                    left: 0;
                                    width: 0;
                                    height: 3px;
                                    background: var(--color-primary);
                                    transition: 0.3s;
                                    bottom: 0;
                                }
                            }
                            &.is-current {
                                a {
                                    color: var(--color-primary);
                                    &::after {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.default-color {
            &.sticky {
                .mainmenunav {
                    ul {
                        &.mainmenu {
                            > li {
                                > a {
                                    color: var(--color-heading);
                                }
                                &.is-current {
                                    a {
                                        color: var(--color-primary);
                                        &::after {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.sticky {
            position: fixed;
            background: #ffffff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            .header-wrapper {
                padding: 0px;
                a {
                    &.rn-btn {
                        color: var(--color-heading);
                        border-color: var(--color-heading);
                        &:hover {
                            color: #ffffff;
                            border-color: var(--color-primary);
                        }
                    }
                }
            }
            .logo {
                a {
                    img {
                        height: 50px;
                        width: auto;
                        &.logo-1 {
                            display: none;
                        }
                        &.logo-2 {
                            display: block;
                        }
                    }
                }
            }
            .humberger-menu {
                span {
                    &.text-white {
                        color: var(--color-heading) !important;
                    }
                }
            }
        }
    }

    &.header-not-transparent {
        &.header--fixed {
            position: static;
            padding-left: 0;
            padding-right: 0;
            padding: 0;
            &.sticky {
                position: fixed;
                background: #ffffff;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                padding: 12px 0;
            }
        }
    }

    &.header--transparent {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: auto;
        padding-left: 50px;
        padding-right: 50px;
        z-index: 999;
        .header-wrapper {
            padding: 30px 0;
        }
        @media #{$lg-layout} {
            padding-left: 30px;
            padding-right: 30px;
        }
        @media #{$sm-layout} {
            padding-left: 30px;
            padding-right: 30px;
        }
        @media #{$large-mobile} {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &.sticky,
    &.header--static,
    &.color-black {
        a {
            &.btn-default {
                &.btn-border {
                    &.btn-opacity {
                        color: #1d1d24;
                        border-color: #1d1d24;
                        &:hover {
                            color: #ffffff;
                            border-color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }

    &.sticky {
        a,
        button {
            &.btn-default {
                padding: 10px 27px;
                @media #{$sm-layout} {
                    padding: 7px 16px;
                }
            }
        }
    }

    &.color-black {
        .humberger-menu {
            span {
                &.text-white {
                    color: var(--color-heading) !important;
                }
            }
        }
    }

    &.logoresize {
        .logo {
            a {
                img {
                    height: 58px;
                    width: auto !important;
                    @media #{$large-mobile} {
                        height: auto;
                    }
                }
            }
        }
    }

    .logo {
        a {
            display: block;
            img {
                max-height: 60px;
            }
        }
    }

    a {
        &.btn-default {
            @media #{$lg-layout} {
                font-size: 14px;
                letter-spacing: 0;
                padding: 10px 27px;
            }
            @media #{$sm-layout} {
                font-size: 14px;
                letter-spacing: 0;
                padding: 7px 16px;
            }
        }
    }
}

.header-right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .header-btn {
        margin-left: 50px;
        @media #{$lg-layout} {
            margin-left: 30px;
        }
        @media #{$large-mobile} {
            margin-left: 0;
        }
    }
}

/*-----------------------------
    Mobile Menu Style CSS 
-------------------------------*/

.header-area {
    .header-wrapper {
        .mainmenunav {
            @media #{$md-layout} {
                position: absolute;
            }
            @media #{$sm-layout} {
                position: absolute;
            }
            ul {
                &.mainmenu {
                    @media #{$md-layout} {
                        width: 60vw;
                        height: 100vh;
                        position: fixed;
                        top: 0;
                        right: -61vw;
                        z-index: 99;
                        padding: 55px;
                        background: #ffffff;
                        -webkit-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
                        -o-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
                        transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
                        display: block;
                        overflow-y: auto;
                    }
                    @media #{$sm-layout} {
                        width: 60vw;
                        height: 100vh;
                        position: fixed;
                        top: 0;
                        right: -61vw;
                        z-index: 99;
                        padding: 55px;
                        background: #ffffff;
                        -webkit-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
                        -o-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
                        transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
                        display: block;
                        overflow-y: auto;
                    }

                    li {
                        @media #{$md-layout} {
                            margin: 0;
                            padding: 10px 0;
                        }

                        @media #{$sm-layout} {
                            margin: 0;
                            padding: 10px 0;
                        }
                        a {
                            @media #{$md-layout} {
                                padding: 0;
                                display: block;
                                color: var(--color-heading);
                            }
                            @media #{$sm-layout} {
                                padding: 0;
                                display: block;
                                color: var(--color-heading);
                            }
                        }
                    }
                }
            }
        }
        .close-menu {
            position: fixed;
            top: 15px;
            opacity: 0;
            visibility: hidden;
            right: 10px;
            z-index: -10;
        }
        &.menu-open {
            .close-menu {
                opacity: 1;
                visibility: visible;
                z-index: 9999;
                span {
                    color: $black-color;
                    font-size: 30px;
                    cursor: pointer;
                }
            }
            .mainmenunav {
                ul {
                    &.mainmenu {
                        -webkit-box-shadow: -5px 0 40px 0 var(--color-gray);
                        box-shadow: -5px 0 40px 0 var(--color-gray);
                        @media #{$md-layout} {
                            right: -1px;
                        }
                        @media #{$sm-layout} {
                            right: -1px;
                        }
                        @media #{$large-mobile} {
                            width: 86vw;
                            padding: 54px 20px;
                        }
                    }
                }
            }
        }
    }
    // &.header-style-two {
    //     .header-wrapper {
    //         @media #{$md-layout} {
    //             padding: 30px 0;
    //         }
    //         @media #{$sm-layout} {
    //             padding: 15px 0;
    //         }
    //         .logo {
    //             a{
    //                 img {
    //                     @media #{$md-layout} {
    //                         width: auto;
    //                     }
    //                     @media #{$sm-layout} {
    //                         width: auto;
    //                     }
    //                 }
    //             }
    //         }
    //         .mainmenunav{
    //             ul {
    //                 &.mainmenu {
    //                     li {
    //                         a {

    //                         }
    //                     }
    //                 }
    //             }
    //         }

    //         ul {
    //             &.social-share {
    //                 li{
    //                     @media #{$sm-layout} {
    //                         margin: 0;
    //                     }
    //                 }
    //             }
    //         }

    //     }
    // }
}

// .header-style-two{
//     .header-right {
//         .header-btn {
//             @media #{$lg-layout} {
//                 margin-left: 7px;
//             }
//         }
//     }
// }
/*------------------------
    Hamberger Menu  
---------------------------*/
.humberger-menu {
    span {
        cursor: pointer;
        @media #{$sm-layout} {
            font-size: 28px;
        }
        @media #{$md-layout} {
            font-size: 28px;
        }
    }
}
.header-wrapper.menu-open .humberger-menu span {
    opacity: 0;
}
