.CookieConsent {
    align-items: center !important;
    background: var(--color-body) !important;
    span {
        &.link {
            cursor: pointer;
            position: relative;
            color: var(--color-midgray);
            letter-spacing: 0.2px;
            text-decoration: underline;
            @extend %transition;

            &:hover {
                transform: translateY(-3px);
                color: var(--color-gray);
            }
        }
    }
}
